import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validationStepOneB } from "../../../config/validationSchemas";
import { FETCH_DEPTOS } from "../../../services/reducers/configReducer/configReducer";
import { prevRequestActions } from "../../../services/reducers/configReducer/prevRequestSlice";
import { stepOneB } from "../../../services/steps.services";
import {
  capitalize,
  errorSetter,
  getDataForRefiAmpli,
  getSubstractYearsToToday,
  handleDate,
  showEmotions,
} from "../../../utils";
import { DateInput, LocalidadAndBarrioInput, TextInput } from "../../Inputs";
import AutoComplete from "../../Inputs/AutoComplete";
import Error from "../../Warnings/Error";
import { isPasarela } from "../Core/Core";
import { getEnum } from "./hooks";
import useGetMinIngresoDeclarado from "./hooks/useGetMinIngresoDeclarado";

const errors = {
  verifyAge: "Seleccione nuevamente su fecha de nacimiento",
  codeIsVerifyingAge: "Solicitud.verify_born_date",
  codeSinOfertas: "Solicitud.no_offers",
  hasDataToVerify:
    "Tiene datos para verificar, una vez verificados podrá continuar.",
};

const StepOneB = ({
  handleNextStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const dispatch = useDispatch();
  const minYearsAgo = 15;
  const maxYearsAgo = 100 + minYearsAgo;
  const theme = useTheme();

  const [openAlert, setOpenAlert] = React.useState(false);
  const [errorText, setErrorText] = React.useState("Ha ocurrido un error");
  const [localidad, setLocalidad] = React.useState([]);
  const deptos = useSelector((state) => state.config.deptos);
  const dataset = useSelector((state) => state.config.dataset);
  const prevRequestStore = useSelector((state) => state.prevRequest);
  const [relacionLaboral, setRelacionLaboral] = React.useState([]);
  const [generos, setGeneros] = React.useState([]);
  const [barrio, setBarrio] = React.useState([]);
  const [showBarrio, setShowBarrio] = React.useState(false);
  const [estudios, setEstudios] = React.useState([]);
  const [estadoCivil, setEstadoCivil] = React.useState([]);
  const minIngresoDeclarado = useGetMinIngresoDeclarado(
    process.env.REACT_APP_MIN_INGRESO_DECLARADO
  );
  const [disabledContinue, setDisabledContinue] = React.useState(false);
  const { pasarelaWebhookUrl } = dataset;

  useEffect(() => {
    getEnum(
      dataset.token,
      "/engines/relacion-laboral/get-all",
      setRelacionLaboral
    );
    getEnum(dataset.token, "/personas/get-all/sexo", setGeneros);
    getEnum(dataset.token, "/nivel-estudio-simple/get-all", setEstudios);
    getEnum(dataset.token, "/personas/get-all/estado-civil", setEstadoCivil);
    dispatch({ type: "core/ampliacion/text/success/show" });
    dispatch({ type: "core/refinanciacion/text/success/show" });
    if (!deptos.length) dispatch(FETCH_DEPTOS());

    // eslint-disable-next-line
    return () => {
      dispatch({ type: "core/ampliacion/text/success/hide" });
      dispatch({ type: "core/refinanciacion/text/success/hide" });
    };

    // eslint-disable-next-line
  }, []);

  const validation = useFormik({
    initialValues: {
      nombre: dataset.nombre,
      apellido: dataset.apellido,
      segundoNombre: dataset.segundoNombre,
      segundoApellido: dataset.segundoApellido,
      ...getInitialValues(dataset, prevRequestStore),
      tycGenerales: false, // Only if is pasarela, else is not required. When first step is skipped, this is not required
    },
    validationSchema: validationStepOneB(
      minYearsAgo,
      maxYearsAgo,
      dataset.nombre,
      dataset.emailOptional,
      minIngresoDeclarado,
      isPasarela()
    ),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = (values) => {
    setOpenAlert(false);
    dispatch({ type: "show/spinner" });
    // Store request
    dispatch(
      prevRequestActions.add({
        cedula: dataset.cedula,
        genero: values.genero,
        fechaNacimiento: values.fechaNacimiento,
        email: values.email,
        ingresos: values.ingresos,
        departamento: deptos.find((depto) => depto.id === values.departamento),
        localidad: localidad.find((local) => local.id === values.localidad),
        relacionLaboral: values.relacionLaboral,
        nivelEstudio: values.nivelEstudio,
        estadoCivil: values.estadoCivil,
      })
    );

    stepOneB(
      dataset.id,
      dataset.nombre !== "" ? dataset.nombre : values.nombre,
      dataset.apellido !== "" ? dataset.apellido : values.apellido,
      dataset.segundoNombre !== ""
        ? dataset.segundoNombre
        : values.segundoNombre,
      dataset.segundoApellido !== ""
        ? dataset.segundoApellido
        : values.segundoApellido,
      deptos.find((depto) => depto.id === values.departamento),
      localidad.find((local) => local.id === values.localidad),
      barrio.length > 0
        ? barrio.find((barr) => barr.id === values.barrio)
        : null,
      values.ingresos,
      values.genero,
      values.relacionLaboral,
      dataset.cedula,
      dataset.telefono,
      moment(values.fechaNacimiento).format("yyyy-MM-DD"),
      // mail opcional
      values.email,
      dataset.canal,
      dataset.sucursal,
      dataset.token,
      getDataForRefiAmpli(dataset),
      values.nivelEstudio,
      values.estadoCivil,
      values.tycGenerales
    )
      .then((response) => {
        if (
          response.data.estadoSolicitud === "SIN_OFERTAS" ||
          response.data.maxMaximoMonto === 0
        ) {
          dispatch({ type: "hide/spinner" });
          return dispatch({
            type: "show/error",
            payload: {
              errorTitle: "Sin ofertas",
              errorBody: dataset.sinOfertasText,
            },
          });
        }
        if (showEmotions(dataset.sucursal)) {
          let emotion = response.data.emotion;
          if (emotion && emotion < 0) {
            if (!pasarelaWebhookUrl) {
              // Si es pasarela, redirige, entonces no ejecuta el timeout
              setTimeout(() => {
                dispatch({ type: "hide/spinner" });
                dispatch({ type: "hide/emotions" });
                dispatch({
                  type: "show/error",
                  payload: {
                    errorTitle: "Sin ofertas",
                    errorBody: dataset.sinOfertasText,
                    errorReaction: true,
                    errorFace: "SadFace",
                  },
                });
              }, 6000);
            }
            return dispatch({ type: "show/sadFace" });
          } else {
            dispatch({ type: "show/expectationFace" });
          }
        }

        dispatch({
          type: "step/one-b",
          payload: {
            nombre: dataset.nombre !== "" ? dataset.nombre : values.nombre,
            apellido:
              dataset.apellido !== "" ? dataset.apellido : values.apellido,
            segundoNombre:
              dataset.segundoNombre !== ""
                ? dataset.segundoNombre
                : values.segundoNombre,
            segundoApellido:
              dataset.segundoApellido !== ""
                ? dataset.segundoApellido
                : values.segundoApellido,
            ingresos: values.ingresos,
            genero: values.genero,
            relacionLaboral: values.relacionLaboral,
            fechaNacimiento: moment(values.fechaNacimiento).format(
              "yyyy-MM-DD"
            ),
            // mail opcional
            email: values.email,
            departamento: deptos.find(
              (depto) => depto.id === values.departamento
            ),
            nivelEstudio: values.nivelEstudio,
            estadoCivil: values.estadoCivil,
            localidad: localidad.find((local) => local.id === values.localidad),
            conOfertas: response.data.estadoSolicitud,

            // Ampliacion / Refinanciacion
            valeHeredadoId: response.data.valeHeredadoId,
            montoHeredado: response.data.montoHeredado,
            generationType: response.data.generationType,
            discountPercentage: response.data.discountPercentage,
          },
        });
        if (activeStep === maxStep) {
          handleMainNextStep();
        } else {
          handleNextStep();
        }
        dispatch({ type: "hide/main" }); // Hide the logo and text
        return dispatch({
          type: "show/spinner/text",
          payload: "Buscando las mejores ofertas para vos...",
        });
      })
      .catch((err) => {
        console.log(err);

        const errMsg = getErrMsg(err.response.data);
        if (isSinOfertas(errMsg)) dispatchSinOfertas(dispatch, dataset);
        else if (isVerifyAge(errMsg)) handleVerifyAge();
        else errorSetter(setOpenAlert, setErrorText, err);

        dispatch({ type: "hide/spinner" });
      });
  };

  function handleVerifyAge() {
    validation.setFieldValue("fechaNacimiento", "");

    setDisabledContinue(true);
    setTimeout(() => {
      setDisabledContinue(false);
    }, 5000);

    // setOpenAlert(true);
    // setErrorText(errors.hasDataToVerify);
  }

  const getLocalidades = async (departamentoId) => {
    setLocalidad([]);
    setShowBarrio(false);
    setBarrio([]);
    try {
      const response = await axios({
        method: "GET",
        headers: { "Access-Control-Allow-Origin": "*" },
        url:
          process.env.REACT_APP_API_URL +
          `/localidades/paisLocalidadesCiudades/${process.env.REACT_APP_COUNTRY_CODE}/${departamentoId}/all`,
      });

      const getLocalidadObj = (l) => ({ label: l.nombre, id: l.id });
      const res = response.data.map(getLocalidadObj);
      setLocalidad(res);
      return res;
    } catch (err) {
      console.log(err);
    }
    return [];
  };

  const handleBarrio = async (e) => {
    setShowBarrio(false);
    setBarrio([]);
    axios({
      method: "GET",
      headers: { "Access-Control-Allow-Origin": "*" },
      url:
        process.env.REACT_APP_API_URL +
        `/localidades/paisLocalidadesCiudades/${process.env.REACT_APP_COUNTRY_CODE}/${e.target.value}/all`,
    })
      .then((response) => {
        setBarrio(
          response.data.map((localidad) => ({
            label: localidad.nombre,
            id: localidad.id,
          }))
        );
      })
      .catch((error) => setBarrio([]));
  };

  // if exist departamento, get localidades, only mount
  useEffect(() => {
    if (validation.values.departamento)
      getLocalidades(validation.values.departamento);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Helper functions
  const isVerifyAge = (errMsg) => errMsg === errors.codeIsVerifyingAge;
  const isSinOfertas = (errMsg) => errMsg === errors.codeSinOfertas;
  const removeFechaValidation = () => (validation.errors.fechaNacimiento = "");
  const isVerifyingAge = () =>
    validation.errors.fechaNacimiento === errors.verifyAge;

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      gap={4}
      onSubmit={validation.handleSubmit}
    >
      <Stack spacing={4} direction={{ xs: "column", sm: "row", md: "row" }}>
        <FormControl required sx={{ width: "100%" }}>
          <TextInput
            id="nombre"
            label="Nombre"
            name="nombre"
            required
            disabled={dataset.nombre !== ""}
            value={validation.values.nombre}
            onChange={validation.handleChange}
            error={
              validation.touched.nombre && Boolean(validation.errors.nombre)
            }
          />
          <FormHelperText sx={{ color: "red" }}>
            {validation.touched.nombre && validation.errors.nombre}
          </FormHelperText>
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <TextInput
            id="segundoNombre"
            name="segundoNombre"
            label="Segundo nombre"
            disabled={dataset.nombre !== ""}
            value={validation.values.segundoNombre}
            onChange={validation.handleChange}
          />
        </FormControl>
      </Stack>
      <Stack spacing={4} direction={{ xs: "column", sm: "row", md: "row" }}>
        <FormControl required sx={{ width: "100%" }}>
          <TextInput
            id="apellido"
            label="Apellido"
            name="apellido"
            required
            disabled={dataset.apellido !== ""}
            value={validation.values.apellido}
            onChange={validation.handleChange}
            error={
              validation.touched.apellido && Boolean(validation.errors.apellido)
            }
          />
          <FormHelperText sx={{ color: "red" }}>
            {validation.touched.apellido && validation.errors.apellido}
          </FormHelperText>
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <TextInput
            id="segundoApellido"
            label="Segundo apellido"
            name="segundoApellido"
            disabled={dataset.apellido !== ""}
            value={
              dataset.segundoApellido !== ""
                ? dataset.segundoApellido
                : validation.values.segundoApellido
            }
            onChange={validation.handleChange}
          />
        </FormControl>
      </Stack>
      <Stack spacing={4} direction={{ xs: "column", sm: "row", md: "row" }}>
        <FormControl required sx={{ width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateInput
              label="Fecha de Nacimiento"
              required
              max={getSubstractYearsToToday(minYearsAgo)}
              min={getSubstractYearsToToday(maxYearsAgo)}
              value={validation.values.fechaNacimiento}
              onChange={(e) => {
                if (isVerifyingAge()) removeFechaValidation();
                validation.setFieldValue("fechaNacimiento", e);
              }}
              error={
                validation.touched.fechaNacimiento &&
                Boolean(validation.errors.fechaNacimiento)
              }
            />
          </LocalizationProvider>
          <FormHelperText sx={{ color: "red" }}>
            {validation.touched.fechaNacimiento &&
              validation.errors.fechaNacimiento}
          </FormHelperText>
        </FormControl>
        <FormControl required sx={{ width: "100%" }}>
          <AutoComplete
            select
            label="Género"
            id="genero"
            required
            fullWidth
            name="genero"
            value={generos.length ? validation.values.genero : ""}
            onChange={validation.handleChange}
            error={
              validation.touched.genero && Boolean(validation.errors.genero)
            }
            items={generos.map((genero, index) => ({
              value: genero.value,
              label: capitalize(genero.value.replace("_", " ")),
            }))}
          />

          <FormHelperText sx={{ color: "red" }}>
            {validation.touched.genero && validation.errors.genero}
          </FormHelperText>
        </FormControl>
      </Stack>
      <Stack spacing={4} direction={{ xs: "column", sm: "row", md: "row" }}>
        {/* mail opcional */}
        {dataset.emailOptional ? (
          <FormControl sx={{ width: "100%" }}>
            <TextInput
              id="email"
              label="Email (Opcional)"
              name="email"
              value={validation.values.email}
              onChange={validation.handleChange}
            />
          </FormControl>
        ) : (
          <FormControl required sx={{ width: "100%" }}>
            <TextInput
              id="email"
              label="Email"
              name="email"
              required
              value={validation.values.email}
              onChange={validation.handleChange}
              error={
                validation.touched.email && Boolean(validation.errors.email)
              }
            />
            <FormHelperText sx={{ color: "red" }}>
              {validation.touched.email && validation.errors.email}
            </FormHelperText>
          </FormControl>
        )}

        <FormControl required sx={{ width: "100%" }}>
          <TextInput
            id="ingresos"
            name="ingresos"
            label="Ingresos mensuales"
            required
            type="number"
            value={validation.values.ingresos}
            onChange={validation.handleChange}
            error={
              validation.touched.ingresos && Boolean(validation.errors.ingresos)
            }
          />
          <FormHelperText sx={{ color: "red" }}>
            {validation.touched.ingresos && validation.errors.ingresos}
          </FormHelperText>
        </FormControl>
      </Stack>
      <Stack spacing={4} direction={{ xs: "column", sm: "row", md: "row" }}>
        <FormControl required sx={{ width: "100%" }}>
          <AutoComplete
            id="departamento"
            name="departamento"
            required
            fullWidth
            select
            label="Departamento"
            value={deptos.length ? validation.values.departamento : ""}
            onChange={(e) => {
              getLocalidades(e.target.value);
              validation.values.localidad = "";
              validation.handleChange(e);
            }}
            error={
              validation.touched.departamento &&
              Boolean(validation.errors.departamento)
            }
            items={deptos.map((depto) => ({
              value: depto.id,
              label: depto.label,
            }))}
          />

          <FormHelperText sx={{ color: "red" }}>
            {validation.touched.departamento && validation.errors.departamento}
          </FormHelperText>
        </FormControl>
        <LocalidadAndBarrioInput
          validation={validation}
          barrio={barrio}
          localidad={localidad}
          showBarrio={showBarrio}
          setShowBarrio={setShowBarrio}
          handleBarrio={handleBarrio}
        />
        {/* falta hacer campo de barrio, con la transición post elegir localidad */}
      </Stack>
      <Stack spacing={4} direction={{ xs: "column", sm: "row", md: "row" }}>
        <FormControl required sx={{ width: "100%" }}>
          <AutoComplete
            id="estadoCivil"
            name="estadoCivil"
            required
            fullWidth
            select
            label="Estado Civil"
            value={estadoCivil.length ? validation.values.estadoCivil : ""}
            onChange={validation.handleChange}
            error={
              validation.touched.estadoCivil &&
              Boolean(validation.errors.estadoCivil)
            }
            items={estadoCivil
              .filter((estado) => estado.extraData2 === "true")
              .sort((a, b) => Number(a.extraData1) - Number(b.extraData1))
              .map((estado) => ({
                value: estado.value,
                label: estado.toolTip,
              }))}
          />
        </FormControl>
        <FormControl required sx={{ width: "100%" }}>
          <AutoComplete
            id="nivelEstudio"
            name="nivelEstudio"
            required
            fullWidth
            select
            label="Nivel de Estudio"
            value={estudios.length ? validation.values.nivelEstudio : ""}
            onChange={validation.handleChange}
            error={
              validation.touched.nivelEstudio &&
              Boolean(validation.errors.nivelEstudio)
            }
            items={estudios.map((estudio) => ({
              value: estudio.value,
              label: estudio.toolTip,
            }))}
          />
        </FormControl>
      </Stack>
      <FormControl required sx={{ width: "100%" }}>
        <AutoComplete
          id="relacionLaboral"
          name="relacionLaboral"
          required
          fullWidth
          select
          label="Relación Laboral"
          value={
            relacionLaboral.length ? validation.values.relacionLaboral : ""
          }
          onChange={validation.handleChange}
          error={
            validation.touched.relacionLaboral &&
            Boolean(validation.errors.relacionLaboral)
          }
          items={relacionLaboral
            .sort((a, b) => a.extraData1 - b.extraData1)
            .map((relacion) => ({
              value: relacion.value,
              label: relacion.toolTip,
            }))}
        />
      </FormControl>
      {isPasarela() && (
        <FormGroup required>
          <FormControlLabel
            value={validation.values.tycGenerales}
            checked={validation.values.tycGenerales}
            onChange={validation.handleChange}
            control={
              <Checkbox
                style={{ color: theme.palette.primary.checkColor }}
                id="tycGenerales"
                name="tycGenerales"
                required
              />
            }
            // onClick={handleClickOpen}
            label={
              <Typography fontSize="small" style={{ display: "inline" }}>
                <a
                  style={{ color: "#000000" }}
                  href={dataset.urlTerminos}
                  target="_blank"
                  rel="noreferrer"
                >
                  Acepto términos y condiciones
                </a>
              </Typography>
            }
          />
          <FormHelperText
            error={
              validation.touched.tycGenerales &&
              Boolean(validation.errors.tycGenerales)
            }
          >
            {validation.touched.tycGenerales && validation.errors.tycGenerales}
          </FormHelperText>
        </FormGroup>
      )}
      <Button
        variant="contained"
        type="submit"
        sx={{ width: "min-content", alignSelf: "flex-end" }}
        disabled={disabledContinue}
        color="button"
      >
        Continuar
      </Button>
      <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
    </Box>
  );
};

export default StepOneB;

/** This function is used to get the initial values of the form, 
 based on the dataset and the previous request stored in the database */
function getInitialValues(dataset, prevRequestStore) {
  // find request in prevRequestStored.documentos with dataset.cedula
  const request = prevRequestStore.documentos.find(
    (doc) => doc.cedula === dataset.cedula
  );

  // find first field in dataset, else find in request if exists. If not, return empty string
  const getField = (field) => {
    let res = dataset[field];
    if (!res && request) res = request[field];
    return res || "";
  };

  return {
    email: getField("email"),
    ingresos: getField("ingresos"),
    fechaNacimiento: moment(handleDate(getField("fechaNacimiento"))).utcOffset(
      0
    ),
    departamento: getField("departamento").id || "",
    localidad: getField("localidad").id || "",
    barrio: "",
    relacionLaboral: getField("relacionLaboral"),
    genero: getField("genero"),
    nivelEstudio: getField("nivelEstudio"),
    estadoCivil: getField("estadoCivil"),
  };
}

function dispatchSinOfertas(dispatch, dataset) {
  dispatch({
    type: "show/error",
    payload: {
      errorTitle: "Sin ofertas",
      errorBody: dataset.sinOfertasText,
      errorReaction: true,
      errorFace: "SadFace",
    },
  });
}

function getErrMsg(err) {
  if (typeof err === "string") return err;
  if (err.returnCode) return err.returnCode;

  try {
    let msg = err.data;
    if (typeof msg === "string") return msg;
    if (msg.returnCode) return msg.returnCode;
  } catch (err) {
    console.log(err);
  }
}
